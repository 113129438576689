import React from 'react'
import ContentLoader from 'react-content-loader'

const Loader = props => {
    return (
        <>
        <ContentLoader viewBox="0 0 800 400" {...props}> 
          <rect x="200.36" y="68.22" width="110.67" height="40.07" rx="18.83" ry="18.83" />    

          <rect x="200.36" y="128.22" width="370.67" height="35.07" rx="3.83" />

          <rect x="200.36" y="188.22" width="370.67" height="18.07" rx="3.83" />
          <rect x="200.36" y="218.22" width="370.67" height="18.07" rx="3.83" />
          <rect x="200.36" y="248.22" width="370.67" height="18.07" rx="3.83" />
          <rect x="200.36" y="278.22" width="370.67" height="18.07" rx="3.83" />

          <rect x="200.36" y="318.22" width="160.67" height="55.07" rx="18.83" ry="18.83" />
        </ContentLoader>
        <ContentLoader viewBox="0 0 800 400" {...props}>
             <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
        </ContentLoader>
        </>
      )
}

export default Loader