import marker from '../../../assets/icons/marker.svg'
import ApplyButton from '../../ApplyButton'
import s from './index.module.scss'
import en_Us from '../../../Language/text'

const Offer = ({ currentVacancy }) => {

  return (
    <>
      <div className={s['offer']}>
        <div className={s['offer__container']}>
          <h1 className={s['offer__title']}>{en_Us.vacancies.offer.title}</h1>
          <div className={s['offer__list']}>
          {currentVacancy.offers.map((p, index) => (
            <div className={s['offer__list-item']} key={index}>
              <img
                className={s['offer__list-item__marker']}
                src={marker}
                alt={'✓'}
              />
              <p className={s['offer__list-item__text']}>
                {p.description}
              </p>
            </div>
          ))}
          </div>
        </div>
      </div>
      <ApplyButton currentVacancy={currentVacancy}/>
    </>
  )
}

export default Offer
