// import LiteYouTubeEmbed from 'react-lite-youtube-embed'
// import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import s from './index.module.scss'
import ReactPlayer from 'react-player'
import video from '../../assets/video/90e886e4-30d5-4cd1-8575-5436e5f01fbc.mp4'
import poster from '../../assets/images/Video/poster.jpeg'

const Video = () => (
    <div className={s['video']}>
      <ReactPlayer
        config={{ file: { attributes: { controlsList: 'nodownload', autoPlay: true } } }}
        onContextMenu={e => e.preventDefault()}
        light={poster}
        url={video}
        width='100%'
        height='783px'
        title='Valorile maib: Suntem profesioniști'
        controls = {true}
      />
      {/* <LiteYouTubeEmbed
        id='EVkzyX7RABI'
        title='Valorile maib: Suntem profesioniști'
        poster='maxresdefault'
      /> */}
      {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/EVkzyX7RABI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
    </div>
);

export default Video
