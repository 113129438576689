import s from './index.module.scss'
import en_Us from '../../../Language/text'

const Abilities = ({ currentVacancy }) => {

  const colors = [
    s['abilities__circle--gray'],
    s['abilities__circle--yellow'],
    s['abilities__circle--red'],
    s['abilities__circle--black'],
    s['abilities__circle--turquoise']
  ];

  return (
    <>
      <div className={s['abilities']}>
        <div className={s['abilities__container']}>
          <h1 className={s['abilities__title']}>{en_Us.vacancies.abilities.title}</h1>
          <div className={s['abilities__circles-wrapper']}>
            <div className={s['abilities__circles']}>
              {currentVacancy.abilities.map((p, index) => (
                <div key={index} className={s['abilities__circle'] + ' ' + colors[index]}>
                  {p.description}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Abilities
