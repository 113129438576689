import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import en_Us from '../../../Language/text'
import ApplyButton from '../../ApplyButton'
import s from './index.module.scss'
import getEnvVars from "../../../env"
import { checkImage } from '../../../utils'

const Description = ({ currentVacancy }) => {

  const { ASSETS_URL } = getEnvVars();

  const isDesktopLayout = useMediaQuery({ query: '(min-width: 1440px)' })

  return (
    <>
      <div className={s['description'] + ' ' +s['description--image']}>
        <div className={s['description__container']} style={{backgroundImage: "url(" + checkImage(ASSETS_URL, currentVacancy?.images?.cover?.url) + ")"}}>
          <Link
            className={s['description__button']}
            to="/"
          >
            <p className={s['description__button__arrow']}>❮ </p>
            <p>{en_Us.back}</p>
          </Link>
          <h1 className={s['description__title']}>{currentVacancy.title}</h1>
          <div className={s['text-wrapper']}> 
              <p className={s['text']}>{currentVacancy.description}</p>
          </div>
          {isDesktopLayout && <ApplyButton position={'top'} />}
        </div>
      </div>
      {!isDesktopLayout && <ApplyButton position={'top'} />}
    </>
  )
}

export default Description
