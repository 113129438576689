import s from './index.module.scss'
import en_Us from '../../../Language/text'

const FirstThings = ({ currentVacancy, fourColumns }) => {

  return (
    <>
      <div className={s['first-things']}>
        <div className={s['first-things__container']}>
          <h1 className={s['first-things__title']}>{en_Us.vacancies.firstThings.title}</h1>
          <div className={s['first-things__list'] + " " +
            (fourColumns && s['first-things__list--four-columns'])
          }>
            {currentVacancy.firstThings.map((p, index) => (
              <div className={s['first-things__list-item']} key={index}>
                <div className={s['first-things__list-item__circle']}></div>
                <p className={s['first-things__list-item__text']}>{p.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default FirstThings
