import marker from '../../../assets/icons/markerWhite.svg'
import s from './index.module.scss'
import en_Us from '../../../Language/text'
import getEnvVars from "../../../env"
import { checkImage } from '../../../utils'

const Experience = ({ currentVacancy }) => {

  const { ASSETS_URL } = getEnvVars();

  return (
    <>
      <div className={s['experience']}>
        <div className={s['experience__container']} style={{backgroundImage: "url(" + checkImage(ASSETS_URL, currentVacancy?.images?.experience?.url) + ")"}}>
          <p className={s['experience__text']}>
            {en_Us.vacancies.experience.title}
          </p>
          <div className={s['experience__list']}>
            {currentVacancy.experiences.map((p, index) => (
              <div className={s['experience__list-item']} key={index}>
                <img
                  className={s['experience__list-item__marker']}
                  src={marker}
                  alt={'✓'}
                />
                <p className={s['experience__list-item__text']}>
                  {p.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Experience
