import React from "react";
import { Route, Routes } from 'react-router-dom'
import Home from '../pages'
import Vacancy from "../pages/Vacancies/Vacancy";
import ScrollToTop from "../components/ScrollToTop";

// import s from './index.module.scss'

const App = () => {

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<Vacancy />} />
      </Routes>
    </>
  )
}

export default App
