import {useState, useEffect, useCallback} from "react";
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Description from '../../../components/Vacancies/Description'
import FirstThings from '../../../components/Vacancies/FirstThings'
import Experience from '../../../components/Vacancies/Experience'
import Qualifications from '../../../components/Vacancies/Qualifications'
import Abilities from '../../../components/Vacancies/Abilities'
import Offer from '../../../components/Vacancies/Offer'
import LoadJotform from '../../../components/LoadJotform'
import Loader from '../../../components/Loader/Loader'
import s from './index.module.scss'
import { useParams } from "react-router-dom";
import getEnvVars from "../../../env"

const Vacancy = () => {

    const { slug } = useParams();
    const { API_URL } = getEnvVars();
    const [vacancy, setVacancy] = useState(null);

    useEffect(() => {
        LoadJotform();
    }, [vacancy]);

    const fetchVacancy = useCallback(() => {
        fetch(API_URL + 'vacancies/' + slug)
           .then((response) => response.json())
           .then((data) => {
              setVacancy(data.data);
           })
           .catch((err) => {
              console.log(err.message);
           });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchVacancy();   
    }, [fetchVacancy]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        <Header />
            <div className={!vacancy ? s['vacancy-page-flex'] : s['vacancy-page']}>
                {vacancy && (
                    <>
                        <Description currentVacancy={vacancy} />
                        <FirstThings currentVacancy={vacancy} fourColumns={false} />
                        <Experience currentVacancy={vacancy} />
                        <Qualifications currentVacancy={vacancy} />
                        <Abilities currentVacancy={vacancy} />
                        <Offer currentVacancy={vacancy} />
                    </>
                )} 
                {!vacancy && <Loader />}
            </div>
        <Footer />
        </>
    )
}

export default Vacancy
