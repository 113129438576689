import {useState, useEffect, useCallback} from "react";
import { Link } from 'react-router-dom'
import en_Us from '../../../Language/text'
import s from './index.module.scss'
import getEnvVars from "../../../env"

const VacanciesHome = () => {

  const {API_URL} = getEnvVars();
  const [vacancies, setVacancies] = useState([]);
  const [categories, setCategories] = useState([]);
  const vacanciesList = [];


  const fetchCategories = useCallback(() => {
    fetch(API_URL + 'categories')
       .then((response) => response.json())
       .then((data) => {
          setCategories(data.data);
       })
       .catch((err) => {
          console.log(err.message);
       });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchVacancies = useCallback(() => {
    fetch(API_URL + 'vacancies')
       .then((response) => response.json())
       .then((data) => {
          setVacancies(data.data);
       })
       .catch((err) => {
          console.log(err.message);
       });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCategories();
    fetchVacancies();
  }, [fetchCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  
  categories.forEach((category, catIndex) => {
    const obj = {};
    categories.forEach((category, index) => {
      let count = vacancies.filter(item => item.category.slug === category.slug).length;
      obj[category.slug] = count;
    });
    if(obj[category.slug] > 0){
      vacanciesList.push(<div key={catIndex}>
        <p className={s['vacancies-home__list-title']}>{category.title}</p>
        <div className={s['vacancies-home__list']}>
          {vacancies.filter((item) => item.category.slug === category.slug).map((vacancy, index) => {
              return( 
                  <Link key={index} className={s['vacancies-home__button']} to={vacancy.slug}>
                    {vacancy.title}
                  </Link>)
          })}
         </div> 
        </div>);
    }
  });

  return (
    <>
      <div className={s['vacancies-home']} id="vacancies">
        <div className={s['vacancies-home__container']}>
          <h1 className={s['vacancies-home__title']}>{en_Us.home.vacancies.title}</h1>
          <div className={s['vacancies-home__content']}>
            {vacanciesList}
          </div>
        </div>
      </div>
    </>
  )
}

export default VacanciesHome
