const LoadJotform = () => {
   
        const mainJotformScriptId = 'jotformFeedbackMain'
        const jotformFeedbackScriptId = 'jotformFeedback'

        const mainJotformScriptExists = document.getElementById(mainJotformScriptId);

        const addJotformFeedbackScript = () => {
            const jotformFeedbackObjectScript = document.createElement('script')
            jotformFeedbackObjectScript.text = 
                `var JFL_220692752372357 = new JotformFeedback({
                    formId: '220692752372357', 
                    base: 'https://form.jotform.com/', 
                    windowTitle: 'Upload Your CV', 
                    background: '#66cdbd', 
                    fontColor: '#FFFFFF', 
                    type: 'false', 
                    height: 500, 
                    width: 700, 
                    openOnLoad: false});`
            jotformFeedbackObjectScript.id = jotformFeedbackScriptId
            document.body.appendChild(jotformFeedbackObjectScript)
        }

        // If it's the very first load of Jotform form wait for the main script to load and
        // only then add JotformFeedback object.
        if (!mainJotformScriptExists) {
            const mainJotformScript = document.createElement('script')
            mainJotformScript.src = 'https://form.jotform.com/static/feedback2.js'
            mainJotformScript.id = mainJotformScriptId
            mainJotformScript.onload = () => {
                addJotformFeedbackScript()
            }
            document.head.appendChild(mainJotformScript)
        }
        else {
            addJotformFeedbackScript()
        }

        return () => {
            const jotformFeedbackObjectScript = document.getElementById(jotformFeedbackScriptId);
            jotformFeedbackObjectScript.remove()
        }
     
}

export default LoadJotform