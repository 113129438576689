const en_Us = {

  back: "Back",
  applyNow: 'Apply now',
  home: {
    description: {
      title: <>Join <br /> the Maib <br /> team</>,
      text: "be part of the biggest digital transformation in Moldova",
      button: "To vacancies"
    },
    abilities: {
      circleGrayTitle: <>We create <br /> products</>,
      circleGray: "that are used by hundreds of thousands of people in Moldova and influence the life of the country",
      circleRedTitle: "We are starting the transition to the Agile model",
      circleRed: "and are radically changing our internal processes and structure",
      circleTurquoiseTitle: <>We are <br /> looking for <br /> strong people</>,
      circleTurquoise: "in order to create the backbone of a future strong product company",
    },
    vacancies: {
      title: "We are looking for",
    },
    theWayWeWork: {
      title: "The way we work",
      list: [
        "Small autonomous, cross-functional teams",
        "Empowered to make decisions",
        "Focused only on a few priorities",
        "Flexible seating arrangements and work-from-home",
      ]
    },
  },

  vacancies: {
      firstThings: {
        title: "The first things you will do",
      },
      experience: {
        title: "Proven knowledge and experience in:",
      },
      qualifications: {
        title: "Qualifications you will need",
        subtitle: "Will be an advantage",
      },
      abilities: {
        title: "Abilities",
      },
      offer: {
        title: "What we offer:",
      },
  }
}


export default en_Us
