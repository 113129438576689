import s from './index.module.scss'
import en_Us from '../../../Language/text'

const Qualifications = ({ currentVacancy }) => {

  return (
    <>
      <div className={s['qualifications']}>
        <div className={s['qualifications__container']}>
          <h1 className={s['qualifications__title']}>{en_Us.vacancies.qualifications.title}</h1>
          <div className={s['qualifications__list']}>
            {currentVacancy.qualifications.map((p, index) => (
              <div className={s['qualifications__list-item']} key={index}>
                <div className={s['qualifications__list-item__point']}></div>
                <p className={s['qualifications__list-item__text']}>{p.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Qualifications
